import React, { useEffect, useState } from "react"
import Helmet from 'react-helmet'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import 'scss/global.scss'
import './layout.scss'
import Logo from 'images/logo.svg'
import ImgDribbble from 'images/dribbble.svg'
import ImgFacebook from 'images/facebook.svg'
import ImgInstagram from 'images/instagram.svg'

const Header = () => {
  let [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function openMobileMenu() {
    setIsMobileMenuOpen(true);
  }

  function closeMobileMenu() {
    setIsMobileMenuOpen(false);
  }

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : '';
  }, [isMobileMenuOpen])

  return (
    <header className="container header">
      <div className="header__col-logo">
        <Link to="/">
          <img src={Logo} alt="Binary Stride Logo" className="logo" />
        </Link>
      </div>
      <div className="header__col-right">
        <div className="header__links">
          <Link to="/#values" className="menu-link">Values</Link>
          <Link to="/#services" className="menu-link">Services</Link>
          <Link to="/#built_here" className="menu-link">Built Here</Link>
          <Link to="/#tech" className="menu-link">Tech</Link>
          <Link to="/blog" className="menu-link">Blog</Link>
          <Link to="/#contact" className="menu-link menu-link--cta">Contact</Link>
        </div>
        <button className="header__hamburger" onClick={openMobileMenu}>☰</button>
      </div>
      {isMobileMenuOpen &&
        <div id="mobile-menu" className="mobile-menu">
          <button className="mobile-menu__btn-close" onClick={closeMobileMenu}>&times;</button>
          <div className="mobile-menu__links">
            <p className="mobile-menu__link">
              <Link to="/#values" className="mobile-menu-link" onClick={closeMobileMenu}>Values</Link>
            </p>
            <p className="mobile-menu__link">
              <Link to="/#services" className="mobile-menu-link" onClick={closeMobileMenu}>Services</Link>
            </p>
            <p className="mobile-menu__link">
              <Link to="/#built_here" className="mobile-menu-link" onClick={closeMobileMenu}>Built Here</Link>
            </p>
            <p className="mobile-menu__link">
              <Link to="/#tech" className="mobile-menu-link" onClick={closeMobileMenu}>Tech</Link>
            </p>
            <p className="mobile-menu__link">
              <Link to="/#contact" className="mobile-menu-link" onClick={closeMobileMenu}>Contact</Link>
            </p>
          </div>
        </div>
      }
    </header>
  )
}

const Footer = () => (
  <footer className="footer">
    <div className={"container footer-content"}>
      <div className="footer-logo">
        <img src={Logo} alt="Binary Stride Logo" className="logo" />
      </div>
      <div className="footer-menu">
        <Link to="/#values" className="menu-link menu-link--footer">Values</Link>
        <Link to="/#services" className="menu-link menu-link--footer">Services</Link>
        <Link to="/#built_here" className="menu-link menu-link--footer">Built Here</Link>
        <Link to="/#tech" className="menu-link menu-link--footer">Tech</Link>
        <Link to="/#contact" className="menu-link menu-link--footer">Contact</Link>
      </div>
    </div>
    <div className="container footer-social-media">
      <a href="https://www.facebook.com/binarystride" className="anchor-icon-social-media" target="_blank" rel="noreferrer">
        <img src={ImgFacebook} alt="facebook" className="icon-social-media" />
      </a>
      <a href="https://www.instagram.com/binarystride" target="_blank" rel="noreferrer" className="anchor-icon-social-media">
        <img src={ImgInstagram} alt="instagram" className="icon-social-media" />
      </a>
      <a href="https://dribbble.com/binarystride" target="_blank" rel="noreferrer" className="anchor-icon-social-media">
        <img src={ImgDribbble} alt="dribbble" className="icon-social-media" />
      </a>
    </div>
    <div className="container" style={{ marginTop: '1rem' }}>
      <div className="footer-copyright">2020 © Binary Stride. All rights reserved.</div>
    </div>
  </footer>
)

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#8365ef" />
        <script src="//code.jivosite.com/widget/BCHfH7ukCB" async></script>
      </Helmet>

      <div className="layout">
        <Header></Header>
        <main className="layout__body">{children}</main>
        <Footer></Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
